import React,{useState,useEffect} from "react"
import DataTable from "react-data-table-component"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"
import axios from "axios"
import { locateOnMap } from "../utils/locateOnMap"

const OrderItems=()=>{
    const { orderId } = useParams();
    const [items,setItems]=useState([])
    const order=useSelector(state=>state.app.order)
    const navigate=useNavigate()
    const formatData=(value)=>{
        if(value===null){
            return 'Null'
        }
        return value
    }
    const columns=[
        {
            name: 'Order No',
            selector: row => row.orderNo,
            sortable: true,
        },
        {
            name: 'Product Name:',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Product Quantity',
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Product Price',
            selector: row => row.price,
            sortable: true,
        },
    ]
    useEffect(()=>{
        if(Object.entries(order).length===0){
            navigate("/orders/1")
        }
        
        async function getAllItems(){
            try{
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/orderItems/${orderId}`)
                const ordersItems=data.map((item,index)=>{
                return {
                    name:item.productName,
                    price:item.orderPrice,
                    quantity:item.orderQuantity,
                    orderNo:item.orderNo
                }
                })
                setItems(ordersItems)
            }
            catch(e){
    
            }

        }
        getAllItems()

    })
    const locateMap=()=>{
        locateOnMap(order.recipientLat,order.recipientLong)
    }
 
return(<div className="body-page">
    <div className="actions-products">
    <div>
        <h3 className="tb-title">Order Details</h3></div>
    </div>
    <div className="top-menu">
     <div className="menu-item item-width-order">
        <h3 className="menu-title">Order No</h3>
        <p className="menu-content">{formatData(order.orderNo)}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Status</h3>
        <p className="menu-content">{formatData(order.status)}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Customer Name</h3>
        <p className="menu-content">{formatData(order.name)}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Contact</h3>
        <p className="menu-content">{formatData(order.contact)}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Transaction Ref</h3>
        <p className="menu-content">{formatData(order.transactionRef)}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">{`Distance (Kms)`}</h3>
        <p className="menu-content">{order.distance}</p>
    </div>
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Delivery Cost</h3>
        <p className="menu-content">{order.deliveryCost}</p>
    </div>
    
    <div className="menu-item item-width-order">
        <h3 className="menu-title">Customer Location</h3>
        <p onClick={()=>{
            locateMap()
        }} className="locator menu-content">
            View Location
        </p>
            
            
            
            {/* <button onClick={(e)=>locateMap()} className="tb-btns">
            View location
            </button> */}
            
           
    </div>
    </div>
<div className="actions-products">
  <div><h3 className="tb-title">Order Items</h3></div>
</div>
<DataTable
columns={columns}
data={items}
pagination
/> 
</div>)
}
export default OrderItems
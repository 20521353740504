import React,{useState,useEffect} from "react";
import logo from '../image/logo.png';
import { NavLink } from "react-router-dom";
// import { IoMdArrowDropdown,IoMdArrowDropup } from "react-icons/io";
import { FaCartPlus } from "react-icons/fa";
import { MdInventory } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { MdRateReview } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const SideLayout=()=>{
  const role=useSelector(state=>state.auth.role)
  const [feebackShow,setFeedbackShow]=useState(true)
  const [onOrder,setOnOrder]=useState(false)
  const [onOutlet,setOnOutlet]=useState(false)
  const [onReview,setOnReview]=useState(false)
  const [onUser,setOnUser]=useState(false)

  const navigate=useNavigate()
  // const [setOrderDisplay,orderDisplay]=useState('none')

  // const Arrow=({status})=>{//IconContext 
  //  return status===true?<IoMdArrowDropup/>:<IoMdArrowDropdown/>
  // }
  const Arrow=({status})=>{
   // const downArrow=(<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path fill="#00e600" d="M128 192l128 128 128-128z"></path></svg>)
    //const upArrow=(<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1.5rem" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path fill="#00e600" d="M128 320l128-128 128 128z"></path></svg>)
    // //status===true?upArrow:downArrow
     const plusBtn=(<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="0.8em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>)
     const minusBtn=(<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="0.8em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"></path></svg>)
    return status===true?minusBtn:plusBtn
   
  }
  const handleOnOrder=()=>{
    const vl=onOrder
    setOnOrder(!vl)
  }
  const handleOnOutlet=()=>{
    const vl=onOutlet
    setOnOutlet(!vl)
  }
  const handleOnReview=()=>{
    const vl=onReview
    setOnReview(!vl)
  }
  const handleOnUser=()=>{
    const vl=onUser
    setOnUser(!vl)
  }

  //({ isActive, isPending }) =>isPending ? "pending" : isActive ? "active" : ""
  const decorator=isActive=>{   
        return {
          color: isActive ? "rgb(6, 182, 6)" : "black",
        }
  }
  // const decorateBg=isActive=>{
  //   return {
  //     backgroundColor: isActive ? "rgb(6, 182, 6)" : "black",
  //   }
  // }
  useEffect(()=>{
        if(role==='LPG'){
          setFeedbackShow(false)
        }
  },[role])
  const logOut=()=>{
    localStorage.setItem("isAuthenticated","false")
    localStorage.setItem("token","")
    localStorage.setItem("refresh","")
    localStorage.setItem("email","")
    localStorage.setItem("role","")
    localStorage.setItem("lastName","")
    //dispatch(auth.logout())
    navigate('/login')
   }
return(
    <div className='side-section'>
      <div className="top-strip-bg">
        <h4 className="top-portal-title">JGASMIS</h4>
      </div>
    <div className='logo'>
      <img src={logo}  alt="logo" className="logo-img"/>
    </div>
    <div className='menu mr-1'>
          <h4 className="menu-sps side-menu-title">Dashboard</h4>
        <div className="sub-menu">
          {/* bg-col */}
          <div className="item-sub">
            <h4 className="sub-menu-title"><b className="menu-icon-spacer"><FaCartPlus/></b>Orders</h4>
            <h4 className="sub-menu-icon"><b className="arrow" onClick={handleOnOrder}><Arrow status={onOrder}/></b></h4>
            {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg> */}
          </div>
          <h5 className={`menu-url link`} style={{display:onOrder===true?'block':'none'}} > <NavLink to={'/orders/1'} style={({ isActive}) =>decorator(isActive) }>Delivered</NavLink></h5>
          <h5 className={`menu-url link`} style={{display:onOrder===true?'block':'none'}}> <NavLink to={'/orders/2'} style={({ isActive}) =>decorator(isActive) }>Rider Enroute</NavLink></h5>
          <h5 className={`menu-url link`} style={{display:onOrder===true?'block':'none'}}> <NavLink to={'/orders/3'} style={({ isActive}) =>decorator(isActive) }>Order Placed</NavLink></h5>
          <h5 className={`menu-url link`} style={{display:onOrder===true?'block':'none'}}> <NavLink to={'/orders/4'} style={({ isActive}) =>decorator(isActive) }>Cancelled</NavLink></h5> 
        </div>
        <div className="sub-menu">
          <div className="item-sub">
            <h4 className="sub-menu-title"><b className="menu-icon-spacer"><MdInventory /></b>Inventory</h4>
            <h4 className="sub-menu-icon"><b className="arrow" onClick={handleOnOutlet}><Arrow status={onOutlet}/></b></h4>
          </div>
          <h5 className="menu-url link" style={{display:onOutlet===true?'block':'none'}}> <NavLink to={'/shops'} style={({ isActive}) =>decorator(isActive) }>Shops</NavLink></h5>
          <h5 className="menu-url link" style={{display:onOutlet===true?'block':'none'}}> <NavLink to={'/products'} style={({ isActive}) =>decorator(isActive) }>Products</NavLink></h5>
        </div>
        <div className="sub-menu">
           <div className="item-sub">
             <h4 className="sub-menu-title"><b className="menu-icon-spacer"><FaUsers /></b>Users</h4>
             <h4 className="sub-menu-icon"><b className="arrow" onClick={handleOnUser}><Arrow status={onUser}/></b></h4>
           </div>
           {role==='ADMIN' && <h5 className="menu-url link" style={{display:onUser===true?'block':'none'}}> <NavLink to={'/user/create'} style={({ isActive}) =>decorator(isActive) }>Create Staff</NavLink></h5>}
          <h5 className="menu-url link" style={{display:onUser===true?'block':'none'}}> <NavLink to={'/customers'} style={({ isActive}) =>decorator(isActive) }>Customers</NavLink></h5>
          {role==='ADMIN' && <h5 className="menu-url link" style={{display:onUser===true?'block':'none'}}> <NavLink to={'/all/users'} style={({ isActive}) =>decorator(isActive) }>Staffs</NavLink></h5>}
          {role==='ADMIN' && <h5 className="menu-url link" style={{display:onUser===true?'block':'none'}}> <NavLink to={'/setting/discount'} style={({ isActive}) =>decorator(isActive) }>Discount</NavLink></h5>}
          {/* <h5 className="menu-url link"> <NavLink to={'/vendors'} style={({ isActive}) =>decorator(isActive) }>Vendors</NavLink></h5> */}
        </div>
        <div className="sub-menu">
         <div className="item-sub">
           <h4 className="sub-menu-title"><b className="menu-icon-spacer"><MdRateReview /></b>Reviews </h4>
           <h4 className="sub-menu-icon"><b className="arrow" onClick={handleOnReview}><Arrow status={onReview}/></b></h4>
         </div>
          {feebackShow===true && <h5 className="menu-url link" style={{display:onReview===true?'block':'none'}}> <NavLink to={'/feedbacks'} style={({ isActive}) =>decorator(isActive) }>Feedbacks</NavLink></h5>}
        </div> 
        <div className="sub-menu">
        <div className="item-sub">
           <h4 className="sub-menu-title"><b className="menu-icon-spacer"><IoLogOut onClick={logOut} /></b>Logout </h4>
         </div>
        </div>
    </div>
  </div>
)
}
export default SideLayout
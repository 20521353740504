import React,{useState} from "react";
import logo from '../image/logo.png';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Error from "../components/Alerts/Error";
const Login=()=>{
  const navigate=useNavigate()
  const [email,setEmail]=useState('')
  const [passcode,setPasscode]=useState('')
  const [valid,setValid]=useState(['',''])
  const [error,setError]=useState(false)
  const [errorMessage,setErrorMessage]=useState('')
  const emailRegex=/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
  const submit=async(e)=>{
    e.preventDefault()
     if(validations()){
      try{
        const {data}=await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/login`,{
            email:email,
            passcode:passcode
            })
           localStorage.setItem("token",data.token)
           localStorage.setItem("refresh",data.refreshToken)
           localStorage.setItem("email",email)
           localStorage.setItem("role",data.role)
           localStorage.setItem("lastName",data.lastName)
           localStorage.setItem("isAuthenticated","true")
           navigate('/')
    }
    catch(e){
      setError(true)
      if(e.code==="ERR_NETWORK"){
        setErrorMessage("Failed check on your internet")
       return
      }
      if(e.response.data.error!==undefined){
        setErrorMessage(e.response.data.error)
        return
      }
      setErrorMessage("Unknown error, reach out admin")
    }
     }
  }
  const validations=()=>{
    let error=["",""]
    let valid=true
    if(email.length===0){
        error[0]="*Email address can't be empty"
        valid=false
    }
    if(email.length!==0){
      if(!emailRegex.test(email)){
        error[0]="*Email address incorrect"
        valid=false
      }
    }

    if(passcode.length===0){
        error[1]="*Passcode can't be empty"
        valid=false
    }
    if(passcode.length>5){
        error[1]="*Passcode must be 5 numbers"
        valid=false
    }
    setValid(error)
    return valid
}
const cancelClick=()=>{
  setError(false)
}

return (
    <div className="login2-main">
        <div className="lg-pt-1">
        <div className="slogan-body">
          <h2 className="slogan-txt">Unlock exceptional culinary experience with J-GAS</h2>
        </div>
        </div>
        <div className="lg-pt-2">
         <div className="login-form-bd">
            <div className="logo2-convas">
                <div className="logo-div-1">
                  <img src={logo}  alt="logo" className="logo2"/>
                </div>
            </div>
            <h4 className="salutation">Welcome Back<span className="greet-emoji">&#128075;</span></h4>
            {error&&(<Error onCancel={cancelClick} message={errorMessage}/>)}
            <form className="form-login-2" onSubmit={submit}>
                  <div className="log2-div mr-t">
                    <h3 className="sign-in">Sign in to your account</h3>
                  </div>
                  <div className="log2-div">
                    <input type="text" className="input-log2" onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address"/>
                    <p className="error">{valid[0]}</p>
                  </div>
                  <div className="log2-div mr-t">
                    <input type="password" className="input-log2"  onChange={(e)=>setPasscode(e.target.value)} placeholder="Please Enter passcode"/>
                    <p className="error">{valid[1]}</p>
                  </div>
                  <div className="forgot-password">
                    <p className="forgot-password-sps">Forgot passcode? </p>
                    <p className="reset-passsword" onClick={()=>{navigate('/user/reset')}}>Reset it here</p>
                  </div>
                  <div className="log2-div mr-t">
                     <button className="signin-btn">Submit</button>
                   </div>
            </form>
         </div>
        </div>
    </div>
)
}
export default Login

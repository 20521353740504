import React,{useEffect,useState} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const Users=()=>{
    const [users,setUsers]=useState([])
    const navigate=useNavigate()
    const columns=[
        {
            name: 'No:',
            selector: row => row.id,
            sortable: true,
        },  
         {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
           
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true, 
        
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,
           
        },
        // {
        //     name: 'Action',
        //     selector: row => row.action,
        //     sortable: true,
        // }
    ]
    /*"LPG Coordinator","ADMIN","Commercial Sales Manager","Product Manager","Customer Service" */
    const makeRoleRead=(role)=>{
        let userRole=""
        switch(role){
            case "LPG ":{
              userRole="LPG Coordinator"
              break
            }
            case "ADMIN":{
                userRole="ADMIN"
                break
            }
            case "CSM":{
                userRole="Commercial Sales Manager"
                break
            }
            case "PM":{
                userRole="Product Manager"
                break
            }
            default: {
                userRole="Customer Service"
              }
        }
        return userRole
    }
    useEffect(()=>{
         async function getAllUsers(){
            try{
                const header={ headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`} }
                const {data}=await axios.get(`${process.env.REACT_APP_APISTAFF}/records/users`,header)
                const mappedUsers=data.map((user,index)=>{
                    return{
                        id:index+1,
                        Name:user.firstName+" "+user.lastName,
                        email:user.email,
                        gender:user.gender,
                        nationalId:user.nationalNo,
                        role:makeRoleRead(user.authorities[0].authority),
                        // action:<Actions user={user}/>
                    }
                });
                setUsers(mappedUsers)
            }
            catch(e){
                navigate('/login')
            }
         }
         getAllUsers()
    })

return(<div className="body-page">
     <h3 className="tb-title">Staffs</h3>
     <DataTable
			columns={columns}
			data={users}
            pagination
		/> 

</div>
)
}
export default Users
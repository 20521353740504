
import axios from "axios";

export const fetchProducts=async()=>{
        try{
          const { data } = await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/allProducts`)
          const products=data.filter((item)=>item.productId!=='').map((prod,index)=>{
              return{
                id:index+1,
                productId:prod.productId,
                name:prod.productName,
                description:prod.productDescription,
                image:prod.productImagePath
            }
            
          })
          console.log("")
          return products
        //  dispatch(app.setProducts(products))
        }
        catch(e){
         console.log('Error ',e)
         return []
        }
      
}
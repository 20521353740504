import React,{useEffect, useState, useMemo} from "react"
import { useParams,useNavigate } from "react-router-dom"
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { app } from "../store/appSlice";
import { fetchProducts } from "../utils/fetchProduct";
const AddProduct=()=>{
    const { typeId }=useParams()
    const { pId }=useParams()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [productName,setProductName]=useState('')
    const [description,setDescription]=useState('')
    const [productId,setProductId]=useState('')
    const [filePath,setFilePath]=useState('')
    const[error,setError]=useState(["","","",""])
    const [title,setTitle]=useState('New Product')
    const [btn,setBtn]=useState('Add')
    const products=useSelector(state=>state.app.products)
    const onSubmit=async(e)=>{
        e.preventDefault()
        if(validations()){
            const jsonBody={
                productId: productId,
                productName: productName,
                productDescription: description,
                productImagePath: filePath
            }
            const type=Number(typeId)
            if(type===0){
                try{
                  await axios.post(`${process.env.REACT_APP_APIURL}/JgasRegistration/createProduct`,jsonBody)
                  const products=await fetchProducts()
                  dispatch(app.setProducts(products))
                   navigate(`/products`)
                }
                catch(e){
 
                }  
            }
            if(type===1){
                try{
                    await axios.put(`${process.env.REACT_APP_APIURL}/JgasRegistration/updateProduct`,jsonBody)
                    const products=await fetchProducts()
                    dispatch(app.setProducts(products))
                    navigate(`/products`)
                }
                catch(e){
                  console.log(`Error in trying access `,e)
                }  
            }
            
        }
    }
    const findProduct=useMemo(()=>{
        const type=Number(typeId)
        if(type===1){
            return products.find((item)=>item.productId===pId)
        }

        return null
    },[typeId,pId,products])
    useEffect(()=>{
        function getProduct(){
            if(findProduct!==null){
                setTitle('Edit product')
                setBtn('Save')
                setProductName(findProduct.name)
                setDescription(findProduct.description)
                setProductId(findProduct.productId)
                setFilePath(findProduct.image)
            }
        }
        getProduct()
    },[findProduct])
    const validations=()=>{
        let error=["","","",""]
        let valid=true
        if(productName.length===0){
            error[0]="* Please provides the product name"
            valid=false
        }
        if(productName.length!==0){
            error[0]=""
        }
      
        if(productId.length===0){
            error[1]="* Please provides a productId"
            valid=false
        }
        if(productId.length!==0){
            error[1]=""
        }
        if(description.length===0){
            error[2]="* Please provides a description"
            valid=false
        }
        if(description.length!==0){
            error[2]=""
        }
        if(filePath.length===0){
            error[3]="* Please provides a file path"
            valid=false
        }
        if(filePath.length!==0){
            error[3]=""
        }
        setError(error)
        return valid
    }
return(<div className="body-page">
      <form onSubmit={onSubmit} className="user-form-create">
      <div><h3 className="tb-title title-mg">{title}</h3></div>
        <div className="sn-item-input">
            <label htmlFor='name' className='form-label'>Product Name:</label>
            <input type="text" className="input-value" id="name" onChange={(e)=>{setProductName(e.target.value)}} value={productName}/>
            <p className="error">{error[0]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='product_id' className='form-label'>Product ID:</label>
            <input type="text" className="input-value" id="product_id" onChange={(e)=>{setProductId(e.target.value)}} value={productId}/>
            <p className="error">{error[1]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='product_desc' className='form-label'>Product Description:</label>
            <input type="text" className="input-value" id="product_desc" onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
            <p className="error">{error[2]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='product_file' className='form-label'>Product File:</label>
            <input type="text" className="input-value" id="product_file" onChange={(e)=>{setFilePath(e.target.value)}} value={filePath}/>
            <p className="error">{error[3]}</p>
        </div>
        <div className="sn-item-input">
            <button className="form-btn">{btn}</button>
        </div>
        </form>
</div>)
}
export default AddProduct
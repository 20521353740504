import axios from "axios";
import { formatedDate } from "./formatedDate";
export const fetchOrders=async(locationId)=>{
        try{
            const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/allShopOrders/${locationId}`)
            const orders=data.map((item,index)=>{
              return{
                id:index+1,
                status:item.status,
                orderNo:item.orderNo,
                paidVia:item.paymentMethod,
                location:item.locationId,
                totalCost:"Ksh "+item.totalCost.toLocaleString()+'.0',
                name:item.customerName,
                contact:item.customerNumber,
                customerLocation:item.specificLocation,
                recipientLat:item.destinationLatitude,
                recipientLong:item.destinationLongitude,
                transactionRef:item.transactionRef,
                distance:item.distance,
                deliveryCost:item.deliveryCost,
                date:formatedDate(item.orderDatetime)
              }
            })
          return orders
        //  dispatch(app.setProducts(products))
        }
        catch(e){
         console.log('Error ',e)
         return []
        }
      
}
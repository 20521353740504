import React,{useEffect,useState} from "react";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { app } from "../store/appSlice";
import DataTable from "react-data-table-component";
import axios from "axios";
const Feedbacks=()=>{
    const [orders,setOrders]=useState([])
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const [feedbacks,setFeedbacks]=useState([])
    const [allFeedbacks,setAllFeedbacks]=useState([])
    
    const Review=({feedback})=>{
        return(<button onClick={()=>{
            dispatch(app.setFeedback(feedback))
            navigate(`/feedback/review`)
        }} className="tb-btn-stl">
          More
        </button>)
      }

    //dispatch
  
    const columns=[
        {
            name: 'No:',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Contact',
            selector: row => row.contact,
            sortable: true,
        },
        {
            name: 'Order No',
            selector: row => row.orderNo,
            sortable: true,
        },
        {
            name: 'Rated',
            selector: row => row.rate,
            sortable: true,
        },
        {
            name: 'Response',
            selector: row => row.response,
            sortable: true,
        },
        {
            name: 'View',
            selector: row => row.view,
            sortable: false,
        },
    ]
    useEffect(()=>{
        console.log("At orders")

        
        async function getAllOrders(){
          //{ headers: {"Authorization" : `Bearer ${token}`} }
          try{
            const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/allOrders`)
            const orders=data.map((item)=>{
              return{
                name:item.customerName,
                contact:item.customerNumber,
                orderNo:item.orderNo,
              }
            })
            setOrders(orders)
           // dispatch(app.setOrders(orders))
          }
          catch(e){
            
        }
       }
       getAllOrders()
      },[])
    useEffect(()=>{
        console.log("At Feedbacks")
        async function getFeedbacks(){
            try{
                //{ headers: {"Authorization" : `Bearer ${token}`} }
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/viewAllSurveys`)
                const mapp_data=data.map((feedback,index)=>{
                const name=orders.find((item)=>item.orderNo===feedback.orderNo)
                    return{
                        id:index+1,
                        orderNo:feedback.orderNo,
                        contact:feedback.phoneNumber,
                        rate:feedback.rate,
                        response:feedback.response,
                        name:name.name,
                        view:<Review feedback={feedback}/>
                    }
                });

                setAllFeedbacks(mapp_data)
                setFeedbacks(mapp_data)
            }
            catch(e){

            }
        }
        getFeedbacks()
    },[orders])
    const search=(e)=>{
        const searchStr=e.target.value
        if(allFeedbacks.length>0){
            const searched=allFeedbacks.filter((item)=>{
                const checker=
                searcMatch(item.name,searchStr)||searcMatch(item.orderNo,searchStr)||searcMatch(item.rate.toString(),searchStr)
                ||searcMatch(item.response,searchStr)||searcMatch(item.contact,searchStr)
              return checker
            }
            )
            setFeedbacks(searched)	
        }
    }
    const searcMatch=(value,search)=>{
    return value.toLowerCase().includes(search.toLowerCase())
    }
return(
    <div className="body-page">
        <div className="dt-actions">
        <div className="dash-item">
           <h3 className="tb-title">Surveys</h3>
        </div>
        <div className="dash-item">
			<div className="search">
				<input type="text" className="search-feedback" onChange={search}  placeholder="Search here ..." />
			</div>
		   </div>
        </div>
         <DataTable
			columns={columns}
			data={feedbacks}
            pagination
		/> 
       
    </div>
)
}
export default Feedbacks
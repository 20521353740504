import { createSlice } from "@reduxjs/toolkit"
const AuthSlice=createSlice({
    name:'auth',
    initialState:{
    isLogin:false,
    accessToken:"",
    refreshToken:"",
    email:"",
    lastName:"",
    role:"",
    user:{}
    },
    reducers:{
    login(state,action){
     const data=action.payload
     state.isLogin=true
     state.accessToken=data.token
     state.refreshToken=data.refresh
     state.email=data.email
     state.role=data.role
     state.lastName=data.lastName
    },
    logout(state){
        state.isLogin=false
        state.accessToken="";
        state.refreshToken="";
        state.phoneNumber=""
    },
    user(state,action){
        state.user=action.payload
    }
   }
   }
)
export const auth=AuthSlice.actions
export default AuthSlice
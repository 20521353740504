import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { auth } from "../store/authSlice";
const Protected=({children})=>{
    const dispatch=useDispatch()
const isAuthenticated=localStorage.getItem("isAuthenticated")//useSelector(state=>state.auth.isLogin)
if(isAuthenticated==="true"){//
    dispatch(auth.login({token:"",refresh:"",email:localStorage.getItem("email"),role:localStorage.getItem("role"),lastName:localStorage.getItem("lastName")}))
    return children
}
return <Navigate to={'/login'}/>
}
export default Protected
import React,{useEffect,useState} from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useSelector} from "react-redux";
import DataTable from "react-data-table-component"
import { FaPlus } from "react-icons/fa6";
// 
const Shops=()=>{
    const [shops,setShops]=useState([])
    // const token=useSelector(state=>state.auth.accessToken)
    const role=useSelector(state=>state.auth.role)
    const navigate=useNavigate()


    const Prices=prop=>{
        return(<button onClick={()=>{
            navigate(`/shop/details/${prop.shop}`)
            // tb-btns
        }} className="tb-btn-stl">
          View
        </button>)
    }
    const Orders=props=>{
        return(<button onClick={()=>{
            navigate(`/shop/orders/${props.shopId}`)
        }} className="tb-btn-stl">
          Orders
        </button>)
    }
    const columns=[
        {
            name: 'No:',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Prices',
            selector: row => row.prices,
            sortable: true,
        },
        {
            name: 'Orders',
            selector: row => row.orders,
            sortable: true,
        },
    ]
    useEffect(()=>{
        async function getAllShops(){
            try{
                //http://89.38.97.47:8080/JgasRegistration/allLocations
                //{ headers: {"Authorization" : `Bearer ${token}`} }
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/allLocations`)
                const mapp_data=data.map((shop,index)=>{
                    return{
                        id:index+1,
                        name:shop.locationName,
                        latitude:shop.latitude,
                        longitude:shop.longitude,
                        description:shop.locationDescrition,
                        status:shop.status,
                        prices:<Prices shop={shop.locationId}/>,
                        orders:<Orders shopId={shop.locationId}/>
                    }
                })
                setShops(mapp_data)
            }
            catch(e){

            }
        }
        getAllShops()
    },[])
    const addShop=()=>{
        navigate("/add/shop/0/0")
    }
    return(
        <div className="body-page">
         <div className="dt-actions dashboard-bg">
		   <div className="dash-item">
             <h3 className="tb-dashboard">Shops</h3>
			</div>
			<div className="dash-item">
                {/* <button className="action-btn" onClick={addShop}>Add Shop</button> */}

            {role==="ADMIN" && <div className="icon-holder mt-r"><FaPlus onClick={addShop}/></div>}
			</div>
		</div>
            {/* <div className="actions-tb">
                <div><h3 className="tb-title">Shops</h3></div>
                {role==="ADMIN" && <div><button className="action-btn" onClick={addShop}>Add Shop</button></div>}
            </div> */}
         <DataTable
			columns={columns}
			data={shops}
            pagination
		/> 
        </div>
    )
}
export default Shops
import React,{useMemo} from "react";
import { GoogleMap,Circle,useLoadScript } from "@react-google-maps/api";
const GetGeofence=()=>{
const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDsjJ0GeTqrhvIf1joDHmKfT1Gta-Ybo70",
      });
   const center = useMemo(() => ({ lat: -1.269697,lng: 36.794462  }), []);
return(
<div className="map-wrapper">
{!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
        mapContainerClassName="map-component"
        zoom={8}
        center={center}
      >
         <Circle
    center={center}
    radius={10000}
    fillOpacity={0.35}
    options={{ fillColor: "#EE80AE33", strokeColor: "#EE80AE66" }}/>
      </GoogleMap>
      )}
</div>
)
}
export default GetGeofence
import React,{useState} from "react";
import logo from '../image/logo.png';
import axios from "axios";
import Error from "../components/Alerts/Error";
import Wait from "../components/Alerts/Wait";
import { useNavigate } from "react-router-dom";
const UserResetPassword=()=>{
  const emailRegex=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const [email,setEmail]=useState('')
  const [valid,setValid]=useState([''])
  const [error,setError]=useState(false)
  const [wait,setWait]=useState(false)
  const [errorMessage,setErrorMessage]=useState('')
  const navigate=useNavigate()
  const submit=async(e)=>{
    e.preventDefault()
    console.log("Email is provide here ",email)
    if(email.length===0){
      setValid(['Please enter the email'])
     return
    }
    if(email.length>0){
      if(!emailRegex.test(email)){
        setValid(['Invalid email'])
        return
      }
    }
    try{
     setWait(true)
     await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/reset/password?email=${email}&admin=0`)
     navigate('/reset/password')
    }
    catch(e){
      setWait(false)
      setError(true)
      if(e.code==="ERR_NETWORK"){
        setErrorMessage("Failed, check on your internet")
       return
      }//
      if(e.code==="ERR_BAD_REQUEST"){
        setErrorMessage("Failed, email not found")
       return
      }
    }
  }
  const cancelClick=()=>{
    setError(false)
  }
return (
    <div className="login2-main">
        <div className="lg-pt-1">
        <div className="slogan-body">
          <h2 className="slogan-txt">Unlock exceptional culinary experience with J-GAS</h2>
        </div>
        </div>
        <div className="lg-pt-2">
         <div className="login-form-bd">
            <div className="logo2-convas">
                <div className="logo-div-1">
                  <img src={logo}  alt="logo" className="logo2"/>
                </div>
            </div>
            <h4 className="salutation">Reset passcode </h4>
            {error&&(<Error onCancel={cancelClick} message={errorMessage}/>)}
            {wait&&(<Wait title={"Please wait ... "} color={"alert-cl"} onCancel={cancelClick}/>)}
            <form className="form-login-2" onSubmit={submit}>
                  <div className="log2-div mr-t">
                    <h3 className="sign-in">Enter your email to reset your passcode</h3>
                  </div>
                  <div className="log2-div">
                    <input type="email" className="input-log2" onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address"/>
                    <p className="error">{valid[0]}</p>
                  </div>
                  <div className="forgot-password">
                    <p className="reset-passsword" onClick={()=>{navigate('/login')}}>Login here</p>
                  </div>
                  <div className="log2-div mr-t">
                  {wait&&( <button className="signin-btn" disabled>Submit</button>)}
                  {wait===false&&( <button className="signin-btn">Submit</button>)}
                   </div>
            </form>
         </div>
        </div>
    </div>
)
}
export default UserResetPassword

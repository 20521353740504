import React,{useState,useEffect} from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { app } from "../store/appSlice";
const AddShop=()=>{
    const [shop,setShop]=useState("")
    const [phone,setPhone]=useState("")
    const [description, setDescription]=useState("")
    const [longitude,setLongitude]=useState("")
    const [latitude,setLatitude]=useState("")
    const [locId,setLocId]=useState("")
    const [btn,setBtn]=useState('Create')
    const [errors,setErrors]=useState(["","","","",""])
    const { typeId } = useParams();
    const { id } = useParams();
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const geocode=useSelector(state=>state.app.geocode)
    //resetGeocode(state)
    const validation=()=>{
        let error=["","","","",""]
        let valid=true
        if(shop.length===0){
            error[0]="* Please provide the shop name"
            valid=false
        }
        if(shop.length!==0){
            error[0]=""
        }
        if(phone.length===0){
            error[1]="* Please provide the phone number"
            valid=false
        }
        if(phone.length!==0){
            const re=/(\\+254|254|0|^){1}[ ]?([7][0-9]|[1][0-1]){1}[0-9]{1}[ ]?[0-9]{6}/
            const validPhone=phone.match(re)
            if(!validPhone){
                error[1]="Invalid number"
                valid=false
            }
            if(validPhone){
                error[1]=""
            }
        }
        if(description.length===0){
            error[2]="* Please provide a simple description"
            valid=false
        }
        if(description.length!==0){
            error[2]=""
        }
        if(latitude===0){
            error[3]="* Please get coordinates"
            valid=false
        }
        if(longitude===0){
            error[3]="* Please get coordinates"
            valid=false
        }
        if(latitude!==0&&longitude!==0){
            error[3]=""
        }

        if(locId.length===0){
            error[4]="* Please provide a location id"
            valid=false
        }
        if(locId.length!==0){
            if(locId.length>10){
            error[4]="* Location id is too long"
            valid=false
            }
            if(locId.length<5){
                error[4]=""
            }
        }
        setErrors(error)
     return valid
    }

    const onSubmit=async(e)=>{
        e.preventDefault()
        const valid=validation()
        if(valid){
            const numType=Number(typeId)
            const dataOb={
                latitude:latitude,
                longitude: longitude,
                locationDescription:description,
                locationId: locId.toUpperCase(),
                locationName: shop,
                phoneNumber: phone,
                status: "ACTIVE"
            }  
                try{
                    if(numType===0){
                      await axios.post(`${process.env.REACT_APP_APIURL}/JgasRegistration/createLocation`,dataOb)
                    }
                    if(numType===1){
                     await axios.put(`${process.env.REACT_APP_APIURL}/JgasRegistration/updateLocation`,dataOb)
                    }
                dispatch(app.resetGeocode())
                navigate('/shops')
                }
                catch(e){

                }
            
        }
    }
    useEffect(()=>{
        setLatitude(geocode.lat)
        setLongitude(geocode.lng)
    },[geocode])
    useEffect(()=>{
        async function getDetails(){
            try{
            const numType=Number(typeId)
            if(numType!==0){
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/JgasRegistration/locationById/${id}`)
                setShop(data.locationName)
                setLocId(data.locationId)
                setPhone(data.phoneNumber)
                setDescription(data.locationDescrition)
                setLongitude(data.longitude)
                setLatitude(data.latitude)
                setBtn('Edit')
            }
            }
            catch(e){

            }
        }
        getDetails()
    },[typeId,id])
return(
    <div className="body-page">
      <form onSubmit={onSubmit} className="user-form-create">
      <h3 className="tb-title title-mg">New Shop</h3>
      {/* <div><h4></h4></div> */}

      <div className="sn-item-input">
            <label htmlFor='geocode' className='form-label'>Geocodes</label>
            <input type="text" className="input-value" id="geocode" value={`${latitude},${longitude}`} disabled/>
            <button className="form-btn" onClick={()=>{navigate('/locate/geocode')}}>Location</button>
            <p className="error">{errors[3]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='name' className='form-label'>Name:</label>
            <input type="text" className="input-value" id="name" onChange={(e)=>{setShop(e.target.value)}} value={shop}/>
            <p className="error">{errors[0]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='phone' className='form-label'>Phone:</label>
            <input type="text" className="input-value" id="phone" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
            <p className="error">{errors[1]}</p>
        </div>
        <div className="sn-item-input">
            <label htmlFor='describe' className='form-label'>Describe</label>
            <input type="text" className="input-value" id="describe" onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
            <p className="error">{errors[2]}</p>
        </div>
        {Number(typeId)===0 &&(<div className="sn-item-input">
            <label htmlFor='locId' className='form-label'>Location ID</label>
            <input type="text" className="input-value" id="locId" onChange={(e)=>{setLocId(e.target.value)}} value={locId}/>
            <p className="error">{errors[4]}</p>
        </div>)}
        <div className="sn-item-input">
            <button className="form-btn">{btn}</button>
        </div>
        </form>
    </div>
)
}
export default AddShop
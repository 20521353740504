import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Wait from "../components/Alerts/Wait";
import axios from "axios";
const CreateUser=()=>{
    const strRegex=/^[a-zA-Z]+$/
    const numRegex= /^[0-9]+$/
    const contactRegex=/(\\+254|254|0|^){1}[ ]?([7][0-9]|[1][0-1]){1}[0-9]{1}[ ]?[0-9]{6}/
    const emailRegex=/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
    // const [title,setTitle]=useState("Create user")
    // const [btn,setBtn]=useState("Save")
    const [nationId,setNationalID]=useState("")
    const [firstName,setFirstName]=useState("")
    const [lastName,setLastName]=useState("")
    const [contact,setContact]=useState("")
    const [altContact,setAltContact]=useState("")
    const [email,setEmail]=useState("")
    const [role,setRole]=useState("LPG")
    const [gender,setGender]=useState("MALE")
    const [btnDisable,setBtnDisable]=useState(false)
    const [alertTxt,setAlertText]=useState("")
    const [color,setColor]=useState('alert-cl')
    const navigate=useNavigate()
    const roles = ["LPG Coordinator","ADMIN","Commercial Sales Manager","Product Manager","Customer Service"];
    const genders=["MALE","FEMALE"]
    const [errors,setErrors]=useState(["","","","",""])
    const onSubmit=async(e)=>{
     e.preventDefault()
      const valid=validation()
      const shortRole=rolePrep(role)
      if(valid){
        try{
     const dataBody={
            email:email,
            ID:nationId,
            contact:contact,
            AltContact:altContact,
            firstName:firstName,
            lastName:lastName,
            gender:gender,
            role:shortRole
     }
        setBtnDisable(true)
        setAlertText("Please wait as the user get created")
        await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/register`,dataBody)
        navigate('/all/users')
        }
        catch(e){
            setColor('alert-error')
            setBtnDisable(false)
            if(e.code==="ERR_NETWORK"){
                setAlertText("Failed check on your internet")
               return
              }
              if(e.code==="ERR_BAD_REQUEST"){
                setAlertText(e.response.data.message)
                return
              }
              setAlertText("Error in creating the user, contact admin")
        }
      }
    }
    const validation=()=>{
        let error=["","","","","",""]
        let valid=true
        if(nationId.length===0){
            error[0]="* Please provide the national ID"
            valid=false
        }
        if(nationId.length!==0){
            
            if(nationId.length<5){
                error[0]="* ID number too short"
                valid=false
            }
            if(nationId.length>8){
                error[0]="* ID number too long"
                valid=false
            }
            if(nationId.length>4&&nationId.length<=8){
                const checkIdValid=numRegex.test(nationId)
                if(!checkIdValid){
                    error[0]="* Invalid ID number, only number"
                    valid=false
                }
                if(checkIdValid){
                    error[0]=""
                }
            }  
        }
        if(firstName.length===0){
            error[1]="* Please provide the first name"
            valid=false
        }
        if(firstName.length!==0){
            const fnameCheck=checkNameValidity(firstName)
            if(!fnameCheck){
                error[1]="* Invalid name, only characters allowed"
                valid=false
            }
            if(fnameCheck){
                error[1]=" "
            } 
        }
        if(lastName.length===0){
            error[2]="* Please provide the last name"
            valid=false
        }
        if(lastName.length!==0){
            const fnameCheck=checkNameValidity(lastName)
            if(!fnameCheck){
                error[2]="* Invalid name, only characters allowed"
                valid=false
            }
            if(fnameCheck){
                error[2]=" "
            } 
        }

        if(contact.length===0){
            error[3]="* Please provide a contact"
            valid=false
        }
        if(contact.length!==0){
            const contactCheck=contactRegex.test(contact)
            if(!contactCheck){
                error[3]="* Invalid contact"
                valid=false
            }
            if(contactCheck){

                error[3]=""
            }
        }
        if(altContact.length===0){
            error[4]="* Please provide an alternative contact"
            valid=false
        }
        if(altContact.length!==0){
            const checkAltenative=contactRegex.test(altContact)
            if(!checkAltenative){
                error[4]="* Invalid contact"
                valid=false
            }
            if(checkAltenative){
                error[4]=""
            }
        }

        if(email.length===0){
            error[5]="* Please provide an email"
            valid=false
        }
        if(email.length!==0){
            const checkEmail=emailRegex.test(email)
            if(!checkEmail){
                error[5]="* Invalid email"
                valid=false
            }
            if(checkEmail){
                error[5]=""
            }
        }
        setErrors(error)
        return valid
    }
    const checkNameValidity=(vl)=>{
    return strRegex.test(vl)
    }
    const rolePrep=(role)=>{
        let userRole="CS"
        switch(role){
            case "LPG Coordinator":{
              userRole="LPG"
              break
            }
            case "ADMIN":{
                userRole="ADMIN"
                break
            }
            case "Commercial Sales Manager":{
                userRole="CSM"
                break
            }
            case "Product Manager":{
                userRole="PM"
                break
            }
            default: {
                userRole="CS"
              }
             
        }
       return userRole
    }
    const onWaitCancel=()=>{
        setAlertText("")
        setColor('alert-cl')
    }
return(
    <div className="body-page">
        <form onSubmit={onSubmit} className="user-form-create">
            {alertTxt.length>0&&( <Wait title={alertTxt} color={color} onCancel={onWaitCancel}/>)}
      <div><h3 className="tb-title title-mg">Create user</h3></div>
      <div className="input-pair-item">
      <div className="user-input-dv">
            <label htmlFor='nationalId' className='form-label'>National Registration:</label>
            <input type="text" max={8} className="input-value" id="nationalId" onChange={(e)=>{setNationalID(e.target.value)}}/>
            <p className="error">{errors[0]}</p>
        </div>
        <div className="user-input-dv">
            <label htmlFor='first-name' className='form-label'>First Name:</label>
            <input type="text" className="input-value" id="first-name" onChange={(e)=>{setFirstName(e.target.value)}}/>
            <p className="error">{errors[1]}</p>
        </div>
      </div>
      <div className="input-pair-item">
        <div className="user-input-dv">
             <label htmlFor='last-name' className='form-label'>Last Name:</label>
            <input type="text" className="input-value" id="last-name" onChange={(e)=>{setLastName(e.target.value)}}/>
            <p className="error">{errors[2]}</p>
        </div>
        <div className="user-input-dv">
            <label htmlFor='name' className='form-label'>Contact:</label>
            <input type="text" className="input-value" id="name" onChange={(e)=>{setContact(e.target.value)}}/>
            <p className="error">{errors[3]}</p>
        </div>
      </div>
      <div className="input-pair-item">
      <div className="user-input-dv">
            <label htmlFor='name' className='form-label'>Alternative Contact:</label>
            <input type="text" className="input-value" id="name" onChange={(e)=>{setAltContact(e.target.value)}}/>
            <p className="error">{errors[4]}</p>
        </div>
        <div className="user-input-dv">
            <label htmlFor='name' className='form-label'>Email:</label>
            <input type="text" className="input-value" id="name" onChange={(e)=>{setEmail(e.target.value)}}/>
            <p className="error">{errors[5]}</p>
        </div>

      </div>
      <div className="input-pair-item">
      <div className="user-input-dv">
           <label htmlFor='name' className='form-label'>Gender:</label>
           <select onChange={(e)=>{setGender(e.target.value)}} className="input-value" >
              {genders.map((option, index) => {
                    return (
                        <option key={index}>
                            {option}
                        </option>
                    );
                })}
            </select>
        </div>
        <div className="user-input-dv">
        <label htmlFor='name' className='form-label'>Roles:</label>
           <select onChange={(e)=>{setRole(e.target.value)}} className="input-value" >
           {roles.map((option, index) => {
                    return (
                        <option key={index}>
                            {option}
                        </option>
                    );
                })}
            </select>
        </div>
      </div>
        <div className="input-item">
            {btnDisable&&(<button className="form-btn" disabled>Saving</button>)}
            {!btnDisable&&(<button className="form-btn">Save</button>)}
        </div>
      </form>

        </div>
)
}
export default CreateUser
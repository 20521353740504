
import './App.css';
import './css/pages.css'
import './css/form.css'
import './css/viewers.css'
import './css/alerts.css'
import './css/login2.css'
import './css/home_dashboard.css'
import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import GeneralLayout from './components/GeneralLayout';
import Home from './pages/Home';
import Feedbacks from './pages/Feedbacks';
import Customers from './pages/Customers';
import Vendors from './pages/Vendor';
import Shops from './pages/Shops';
import Login1 from './pages/Login1';
import Products from './pages/Products';
import AddProduct from './pages/AddProduct';
import Prices from './pages/Prices';
import ProductPrice from './pages/ProductPrice';
import AddProductPrice from './pages/AddProductPrice';
import OrderItems from './pages/OrderItems';
import AddShop from './pages/AddShop';
import MainOrders from './pages/MainOrders';
import Protected from './components/Protected';
import ShopOrders from './pages/ShopOrders';
import ShopDetails from './pages/ShopDetails';
import Review from './pages/Review';
import UpdatePrice from './pages/UpdatePrice';
import Users from './pages/Users';
import GiveRole from './pages/GiveRole';
import CreateUser from './pages/addUser';
import Locate from './pages/GetGeocodes';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Setting';
import EnableDiscount from './pages/EnableDiscount';
import Login from './pages/Login';
import UserResetPassword from './pages/UserReset';
import GetGeofence from './pages/GetGeoGefence';
import SaleRole from './pages/SalesRole';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<GeneralLayout/>}>
          <Route index element={<Protected><Home/></Protected>}/>
          <Route path='/orders/:orderFt' element={<Protected><MainOrders/></Protected>}/>
          <Route path='/customers' element={<Protected><Customers/></Protected>}/>
          <Route path='/vendors' element={<Protected><Vendors/></Protected>}/>
          <Route path='/feedbacks' element={<Protected><Feedbacks/></Protected>}/>
          <Route path='/shops' element={<Protected><Shops/></Protected>}/>
          <Route path='/products' element={<Protected><Products/></Protected>}/>
          <Route path='/add/product/:typeId/:pId' element={<Protected><AddProduct/></Protected>}/>
          <Route path='/shop/:shopId' element={<Protected><Prices/></Protected>}/>
          <Route path='/prodprice/:shopId' element={<Protected><ProductPrice/></Protected>}/>
          <Route path='/product/:shopId/:productId' element={<Protected><AddProductPrice/></Protected>}/>
          <Route path='/order/items/:orderId' element={<Protected><OrderItems/></Protected>}/>
          <Route path='/add/shop/:typeId/:id' element={<Protected><AddShop/></Protected>}/>
          <Route path='/shop/orders/:shopId' element={<Protected><ShopOrders/></Protected>}/>
          <Route path='/shop/details/:shopId' element={<Protected><ShopDetails/></Protected>}/>
          <Route path='/feedback/review' element={<Protected><Review/></Protected>}/>
          <Route path='/prodprice/update/:shopId' element={<Protected><UpdatePrice/></Protected>}/>
          <Route path='/all/users' element={<Protected><Users/></Protected>}/>
          <Route path='/user/action' element={<Protected><GiveRole/></Protected>}/>
          <Route path='/user/create' element={<Protected><CreateUser/></Protected>}/>
          <Route path='/setting/discount' element={<Protected><Settings/></Protected>}/>
          <Route path='/discount/staff/:phone_number' element={<Protected><EnableDiscount/></Protected>}/>
          <Route path='/sales/role/:phone_number' element={<Protected><SaleRole/></Protected>}/>
        </Route>
        <Route path='/locate/geocode' element={<Protected><Locate/></Protected>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/geofence' element={<GetGeofence/>}/>
        <Route path='/reset/password' element={<ResetPassword/>}/>
        <Route path='/user/reset' element={<UserResetPassword/>}/>
        <Route path='/login1' element={<Login1/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
